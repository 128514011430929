<template>
  <v-client-table
    id="dataTable"
    :data="data"
    :options="options"
    :columns="columns"
    :theme="theme"
  >
    <span
      slot="reportDate"
      slot-scope="props"
    >
      <span v-if="period === 'week'">
        {{ formatWeek(props.row.date) }}
      </span>
      <span v-else-if="period === 'month'">
        {{ formatMonth(props.row.date) }}
      </span>
      <span v-else>
        {{ formatDate(props.row.date) }}
      </span>
    </span>
    <span
      slot="routeTemplates"
      slot-scope="props"
    >
      <span v-for="routeTemplate in props.row.routeTemplates">
        {{ `${routeTemplate.code} - ${routeTemplate.route_count} шт (${routeTemplate.win_route_summ})` }}<br>
      </span>
    </span>
    <span
      slot="summ"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.summ)) }} руб.<br>
      <span
        v-if="alternativeCalculation"
      >
        {{ alternativeAmountValue(props.row.with_nds, props.row.summ) }}
      </span>
    </span>
    <span
      slot="win_summ"
      slot-scope="props"
    >
      {{ numberRUFormat(Math.round(props.row.win_summ)) }} руб.<br>
      <span
        v-if="alternativeCalculation"
      >
        {{ alternativeAmountValue(props.row.with_nds, props.row.win_summ) }}
      </span>
    </span>
    <span
      slot="savingOverrun"
      slot-scope="props"
    >
      <span
        v-if="(props.row.summ - props.row.win_summ) > 0"
        class="text-success"
      >
        {{ numberRUFormat(Math.round(props.row.summ - props.row.win_summ)) }} руб.<br>
        <span
          v-if="alternativeCalculation"
        >
          {{ alternativeAmountValue(props.row.with_nds, props.row.summ - props.row.win_summ) }}
        </span>
      </span>
      <span
        v-else-if="(props.row.summ - props.row.win_summ) < 0"
        class="text-danger"
      >
        {{ numberRUFormat(Math.round(props.row.summ - props.row.win_summ)) }} руб.<br>
        <span
          v-if="alternativeCalculation"
        >
          {{ alternativeAmountValue(props.row.with_nds, props.row.summ - props.row.win_summ) }}
        </span>
      </span>
      <span v-else>0 руб.</span>
    </span>
    <tr
      slot="appendBody"
    >
      <td />
      <td />
      <td />
      <td />
      <td><b>{{ numberRUFormat(Math.round(total.summ)) }} руб.</b></td>
      <td><b>{{ numberRUFormat(Math.round(total.win_summ)) }} руб.</b></td>
      <td>
        <span
          v-if="(total.summ - total.win_summ) > 0"
          class="text-success"
        ><b>{{ numberRUFormat(Math.round(total.summ - total.win_summ)) }} руб.</b></span>
        <span
          v-else-if="(total.summ -total.win_summ) < 0"
          class="text-danger"
        ><b>{{ numberRUFormat(Math.round(total.summ -total.win_summ)) }} руб.</b></span>
        <span v-else><b>{{ numberRUFormat(Math.round(total.summ -total.win_summ)) }} руб.</b></span>
      </td>
    </tr>
  </v-client-table>
</template>

<script>
import {ClientTable, Event} from 'vue-tables-2';
import {numberToRUFormat, valueToValueWithoutVAT, valueToValueWithVAT} from '@/components/mixins/helpers';
import Vue from 'vue';
import moment from 'moment';

Vue.use(ClientTable);


export default {
  name: 'AuctionCategoryTable',
  props: {
    data: {
      type: Array,
      default: function() {
        return [];
      },
    },
    total: {
      type: Object,
      default: function() {
        return {};
      },
    },
    period: {
      type: String,
      default: '',
    },
    alternativeCalculation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        'reportDate',
        'name',
        'routeTemplates',
        'contractor',
        'summ',
        'win_summ',
        'savingOverrun',
      ],
      options: {
        headings: {
          'reportDate': 'Период',
          'name': 'Название тендера',
          'routeTemplates': 'Маршруты рейсов с кол-вом и окончательной стоимостью',
          'contractor': 'Перевозчик',
          'summ': 'Начальная стоимость маршрутов по тендеру',
          'win_summ': 'Фактическая стоимость маршрутов по тендеру',
          'savingOverrun': 'Экономия/Перерасход',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 10,
        perPageValues: [10, 25, 50],
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      theme: 'bootstrap4',
    };
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    formatWeek(date) {
      return date ? moment(date).format('WW Неделя YYYY') : '';
    },
    formatMonth(date) {
      return date ? moment(date).format('MM.YYYY') : '';
    },
    numberRUFormat(value) {
      return numberToRUFormat(value);
    },
    alternativeAmountValue(vat, value) {
      if (value) {
        if (!vat) {
          return '(' + this.numberRUFormat(valueToValueWithVAT(value)) + ' руб. С НДС)';
        } else {
          return '(' + this.numberRUFormat(valueToValueWithoutVAT(value)) + ' руб. Без НДС)';
        }
      } else {
        return '';
      }
    },
  },
};
</script>
